import { render, staticRenderFns } from "./takeExam.vue?vue&type=template&id=3324dedb&scoped=true&"
import script from "./takeExam.vue?vue&type=script&lang=js&"
export * from "./takeExam.vue?vue&type=script&lang=js&"
import style0 from "./takeExam.vue?vue&type=style&index=0&id=3324dedb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3324dedb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VContainer,VRow,VSimpleTable,VTab,VTabs})
