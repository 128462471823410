<template>
  <v-container>
    <v-row>
      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab v-for="item in items" :key="item" :to="link_tab(item)">
          {{ item.content }}
        </v-tab>
      </v-tabs>
    </v-row>
      <v-simple-table
          class="elevation-1 mx-auto mt-5">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="align-center text-left">
              Tiêu đề
            </th>
            <th class="align-center text-center">
              Thời gian thi
            </th>
            <th class="align-center text-center">
              Tổng điểm
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item, index) in getTakeExamListUser"
              :key="index"
          >
            <td class="text-left">{{ item.exam_meta_title }}</td>
            <td class="text-center">{{ item.start_date_time }}</td>
            <td class="text-center">{{ item.total_score }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
  </v-container>
</template>

<script>


import { GET_TAKE_EXAM_LIST_USER } from "@/store/exams.module";

export default {

  data: () => {
    return {
      tab: 0,
      items: [
        {
          content: "Chi tiết học viên",
          link: "/user/student/",
          path: "info"
        },
        {
          content: "Các khóa đã học",
          link: "/user/student/",
          path: "course"
        },
        {
          content: "Khóa giao tiếp 1-1",
          link: "/user/student/",
          path: "OneOneCourse"
        },
        {
          content: "Các bài thi",
          link: "/user/student/",
          path: "takeExam"
        }
      ],
      headers: [
        {
          text: "Tiêu đề",
          align: "start",
          sortable: false,
          value: "exam_meta_title"
        },
        { text: "Tổng điểm", value: "getTakeExamListUser.total_score" },
        { text: "Thời gian thi", value: "getTakeExamListUser.start_date_time" },
      ]
    };
  },
  computed: {
    getTakeExamListUser() {
      return this.$store.getters.getTakeExamListUser;
    }
  },
  created() {
    console.log(1212)
    let user_id = this.$route.params.id;
    console.log(user_id)
   this.getList(user_id);
  },
  methods: {
    link_tab(link) {
      return link.link + this.$route.params.id + "/" + link.path;
    },
    getList(user_id) {
      this.$store.dispatch(GET_TAKE_EXAM_LIST_USER, { user_id: user_id });
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  width: 90% !important;
}

.v-application .primary {
  background-color: limegreen !important;
}
</style>
